.left-sidebar-container{
    height: 100%;
    background: #ffffff;
    width: 470px;
    min-width: 470px;
    position: relative;
    /* padding: 12px 24px */
}

.track-keywords__actions {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 12px 24px;
    background: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid var(--default-surfaces-light)
}


.track-keywords__actions.loading * {
    pointer-events: none !important;
}
.track-keywords__actions.loading > * {
    opacity: 0.4;
}

.track-keywords__actions > * {
    padding: 8px 16px;
    border-radius: 50px;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.track-keywords__actions > *:first-child {
    flex-grow: 1;
}

.track-keywords__actions .track-keyword {
    background: var(--default-surfaces-anchor);
    color: var(--inverted-symbols-primary);
}
.track-keywords__actions .cancel-track-keyword {
    background: var(--default-surfaces-light);
    color: var(--default-surfaces-anchor);
}

.track-keywords__actions svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
}
.track-keywords__actions path {
    fill: var(--inverted-symbols-primary);;
}

.left-sidebar-container .header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px
}

.left-sidebar-container .header-container .sidebar-top-header{
    color: var(--default-symbols-primary);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
    letter-spacing: -0.48px;
    /* min-width: 470px; */
}

.left-sidebar-container-subheader {
    padding: 0 24px 16px;
}

.left-sidebar-container-header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-sidebar-container-header .left-sidebar-heading{
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
}

.left-sidebar-container-content {
    padding: 0 24px 50px;
    max-width: 422px;
    box-sizing: content-box;
}

.left-sidebar-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--default-symbols-primary);
}
.left-sidebar-container-header .action {
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 4px;
    cursor: pointer;
    margin-bottom: -3px;
}
.left-sidebar-container-header .action.disabled {
    cursor: default;
    opacity: 0.5;
}
.left-sidebar-container-header .action .actionText {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
}
.left-sidebar-container-header .action .actionIcon {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-sidebar-container-header .action .actionIcon svg {
    fill: var(--default-symbols-primary);
  }