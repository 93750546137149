.link-bubble-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.link-url {
  color: #0066cc;
  text-decoration: none;
  font-size: 14px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-url:hover {
  text-decoration: underline;
}

.link-actions {
  display: flex;
  gap: 8px;
  border-left: 1px solid #eee;
  padding-left: 8px;
}

.link-action-button {
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  color: #666;
}

.link-action-button:hover {
  background: #f5f5f5;
}

.link-action-button.edit {
  color: #0066cc;
}

.link-action-button.delete {
  color: #dc3545;
}

.link-action-button.delete:hover {
  background: #fff5f5;
}
