.editor-content-info input {
  background: var(--default-surfaces-light);
  border: 2px solid transparent;
}
.editor-content-info input:focus {
  border-color: var(--hero-borders-anchor);
  background: var(--default-surfaces-base)
}
.current-article-modal .input__action {
  cursor: initial !important;
}
.current-article-modal .has--error .info-text {
  display: none;
}