.text-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--default-surfaces-base);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--default-borders-light, rgba(23, 23, 23, 0.05));
  padding: 2px;
  border-radius: 8px;
  gap: 4px;
}
.divider {
  background: var(--default-borders-light);
  width: 1px;
  height: 12px;
  border-radius: 2px;
  margin: 0 2px;
}
.text-menu button > svg {
  width: 16px;
  height: 16px;
}
.text-menu .text-menu-right{
  display: flex;
  align-items: center
}

.upload-progress-wrapper {
  background: white;
  padding: 30px;
  border-radius: 20px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.upload-progress-message {
  font-size: 14px;
  color: #384359;
  font-weight: 700;
  margin-bottom: 10px;
}

.upload-progress-error {
  font-size: 14px;
  color: #ec5032;
  font-weight: 700;
  margin-bottom: 10px;
}

.upload-progress-bar {
  width: 200px;
  height: 14px;
  background-color: #b5b5c3;
  border-radius: 9px;
}

.text-menu button svg {
  fill: var(--default-symbols-primary)
}

.text-menu button:not(.is-disabled):hover {
  background-color: var(--default-surfaces-ghost-hv)
}

.text-menu button.is-active {
  background-color: var(--default-surfaces-anchor);
}
.text-menu button.is-active svg {
  fill: var(--inverted-symbols-primary);
}

.text-menu button.is-disabled {
  opacity: 0.25;
  cursor: not-allowed;
}