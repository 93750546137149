.link-editor-panel {
  padding: 12px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.link-input-group {
  margin-bottom: 12px;
}

.link-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.link-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.link-checkbox input[type="checkbox"] {
  margin-right: 4px;
}

.link-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.link-button {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.link-button.cancel {
  background: #f5f5f5;
  color: #666;
}

.link-button.cancel:hover {
  background: #eee;
}

.link-button.apply {
  background: #0066cc;
  color: white;
}

.link-button.apply:hover {
  background: #0052a3;
}

.link-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
