.editor-content-info__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.article-actions {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-grow: 1;
}