.app-modal.confirmation-modal {
    z-index: 22;
}
.confirmation-modal .app-modal-text {
    padding-right: 36px;
}

.confirmation-modal .app-modal-button {
    background: var(--default-surfaces-light);
    padding: 8px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
    width: 50%;
}

.confirmation-modal .app-modal-button:first-child:last-child {
    width: 100%;
}

.confirmation-modal .app-modal-button:not(.app-modal-button--secondary) {
    background: var(--night-surfaces-anchor);
    color: var(--inverted-symbols-primary);
}

.confirmation-modal.delete-article .app-modal-button:not(.app-modal-button--secondary) {
    background: var(--default-symbols-primary);
    color: var(--inverted-symbols-primary);
}

.confirmation-modal .app-modal-button:not(.app-modal-button--secondary) path {
    fill: var(--inverted-symbols-primary);
}
