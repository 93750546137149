.ai-commands-menu {
    min-width: 200px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 4px;
}

.ai-commands-menu .menu-item {
    display: flex;
    align-items: center;
}

.ai-command-button {
    display: block;
    width: 100%;
    padding: 8px 12px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    background: var(--night-surfaces-light);
    border-radius: 20px;
    padding: 6px 8px;
}

.submenu-container {
    position: relative;
}

.submenu-trigger {
    display: block;
    width: 100%;
    padding: 8px 12px;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    color: #333;
}

.submenu-trigger:hover {
    background-color: #f5f5f5;
    border-radius: 4px;
}

.submenu-container:hover .submenu {
    display: block;
}

.submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    min-width: 180px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 4px;
}

.ai-prompt-popup {
    z-index: 1000;
}

.ai-prompt-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    min-width: 300px;
}

.ai-prompt-textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 14px;
    resize: vertical;
}

.ai-prompt-actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

.ai-prompt-submit,
.ai-prompt-cancel {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.ai-prompt-submit {
    background-color: #007bff;
    color: white;
}

.ai-prompt-submit:hover {
    background-color: #0056b3;
}

.ai-prompt-cancel {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
}

.ai-prompt-cancel:hover {
    background-color: #e9ecef;
}

.custom-prompt-container {
    padding: 6px;
}

.custom-prompt-textarea {
    width: 100%;
    min-height: 58px;
    padding: 8px;
    border-radius: 12px;
    background: rgba(23, 23, 23, 0.05);
    resize: none;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: rgba(23, 23, 23, 0.5);
    outline: none;
    border: none;
}

.custom-prompt-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

.custom-prompt-button {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 6px 8px;
    border: none;
    cursor: pointer;
}

.custom-prompt-button.submit {
    color: var(--night-symbols-primary);
    background: var(--night-surfaces-light);
}

.ai-commands-trigger {
    color: var(--night-symbols-primary);
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 4px;
    gap: 2px;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    border-radius: 6px;
    cursor: pointer;
}
.ai-commands-trigger svg {
    color: var(--default-symbols-primary)
}
.ai-commands-trigger:hover {
    background: rgba(23, 23, 23, 0.05)
}
.ai-commands-content .popup-arrow {
    display: none;
}

[role=tooltip].ai-commands-content {
    width: 320px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
    display: grid;
    gap: 7px;
    border-radius: 6px;
    border: none;
    padding: 0;
    margin-top: -4px;

}
