.traffic-info-wrapper {
  position: relative;
}

.traffic-info__search-volume {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 146.667% */
  letter-spacing: -0.01em;
  padding: 4px 8px;
  cursor: pointer;
  position: relative;
  background: var(--default-surfaces-ghost-hv);
  color: var(--default-surfaces-anchor);
  border-radius: 50px;
  margin-right: 6px;
}
