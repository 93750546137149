input[disabled] {
  pointer-events: none;
}
.label {
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
}

.label strong {
  font-weight: 700;
}
.input__wrapper {
  position: relative;
  color: var(--default-symbols-primary);
}
.input__wrapper svg {
  height: 16px;
  width: 16px;
  fill: var(--default-symbols-primary);
}
.input {
  padding: 26px 16px 12px;
  border-radius: 12px;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  border: none;
  text-overflow: ellipsis;
  border: 2px solid transparent;
}

.input__wrapper.has-icon .input {
  padding-right: 56px;
}

.input__label {
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
  position: absolute;
  left: 18px;
  top: 12px;
}

.modal-start-article--active .input,
.input:focus {
  background: var(--default-surfaces-base);
  border-color: var(--hero-borders-anchor);
  color: var(--default-symbols-primary);
  outline: none;
}

.input__wrapper--loading path {
  fill: var(--default-symbols-primary)
}

.input__wrapper:not(.input__wrapper--loading) .input__action {
  opacity: 0.5;
  cursor: pointer;
}

.spinner-icon path {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.input__action {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.info-text {
  color: var(--default-symbols-secondary);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  margin-top: 8px;
}


.has--error .input,
.modal-start-article--error .input {
  background: var(--negative-surfaces-light) !important;
  border-color: transparent !important;
  outline: none !important;
}
.has--error .info-text {
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
}
.has--error .info-text,
.modal-start-article--error .info-text {
  color: var(--danger-symbols-primary) !important;
}
