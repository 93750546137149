.feedback-box {
    max-width: 800px;
    margin: 16px auto 0;
    border: 2px solid var(--default-borders-light);
    padding: 12px 34px;
    border-radius: 8px;
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.feedback-box__actions {
    display: flex;
    align-items: center;
    gap: 4px;
}
.feedback-box__actions button {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer
}
.feedback-box__actions button.is-active {
    background-color: var(--default-surfaces-light-hv);
}
.feedback-box__actions svg {
    width: 14px;
    height: 14px;
}

.feedback-box__actions path {
    fill: var(--default-symbols-primary);
}
