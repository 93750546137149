
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.score-card-container {
    border-radius: 12px;
    padding: 16px;
    background: var(--default-surfaces-light);
    width: 100%;
    display: flex;
    gap: 24px;
    /*margin-top: 8px;*/
    cursor: pointer;
    justify-content: space-between;
}
.score-card-container .first-score-section{
    position: relative;
}
.score-card-container .progress-score.spinning svg {
    animation: rotate 2s linear infinite;
}
/*.score-card-container .progress-score .loading-block {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/
.score-card-container .progress-score{
    height: 116px;
    width: 116px;
    position: relative
}
.progress-score .spinner-icon {
    width: 38px;
    height: 38px;
    fill: var(--night-borders-anchor)
}
.score-card-container--article .progress-score .spinner-icon {
    fill: var(--hero-borders-anchor)
}

.score-description{
    margin-top: 28px;
    gap: 2px;
    display: grid;
}

.score-description .score-title{
    color: var(--default-symbols-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.score-description  .score-competition-description{
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--night-symbols-primary);
    display: flex;
    align-items: center;
}
.score-description .score-competition-description path {
    fill: var(--night-symbols-primary);
}
.score-description  .score-competition-description:hover {
    color: var(--night-symbols-primary-hv)
}
.score-description .score-competition-description:hover path {
    fill: var(--night-symbols-primary-hv);
}
.score-description .score-competition-description svg {
    width: 14px;
}
.score-card-container .second-score-section{
    flex-grow: 1;
    max-width: 183px;
}

.score-card-container .progress-circular-text{
    font-weight: 700;
    color: var(--default-symbols-primary, #171717);
    font-size: 32px
}
.progress-bar__wrapper{
    margin-top: 4px
}
.progress-bar__wrapper.clickable:not(.loading) * {
    cursor: pointer;
}
.progress-bar__wrapper.loading {
    font-size: 0;
    padding: 4px 0;
}

.score-card-container .score-card--compact .progress-score {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;

}

.score-card-container--compact.score-card-container {
    position: sticky;
    top: -100px;
    margin-top: -100px;
    transition: 0.5s ease;
    background: #f3f3f3;
    z-index: -1;
    opacity: 0;
    margin-bottom: 57px;
}

.score-card-container--compact.score-card-container.compact-mode {
    margin-top: 0;
    top: 0;
    z-index: 1;
    opacity: 1;
}
.score-card--compact {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    gap: 12px;
}
.score-card--compact .progress-circular-text {
    font-size: 11px;
    line-height: 14px;
}
.score-percentages {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    gap: 7px;
    margin-top: 4px;
}