.feedback-modal__textarea {
    width: 100%;
    min-height: 120px;
    background: var(--default-surfaces-light);
    padding: 12px 16px;
    border: none;
    border-radius: 12px;
    resize: vertical;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
}

.feedback-modal__textarea::placeholder {
    color: #9CA3AF;
}

.feedback-modal__textarea:focus {
    outline: none;
    border-color: #6B7280;
}