.editor-content-info {
  margin-bottom: 12px;
}
.editor-content-info.disabled {
  opacity: 0.5;
}
.editor-content-info.disabled * {
  pointer-events: none;
}
.editor-content-info:not(:empty) {
  margin-bottom: 24px;
}

/* .editor-content-info__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
} */
.editor-content-info__header .read-only {
  color: var(--default-symbols-secondary);
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-right: 8px;
}
.editor-content-info__header .read-only svg {
  width: 14px;
  height: 14px;
  fill: var(--default-symbols-secondary);
}

/*.article-switcher {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  gap: 8px;*/
/*}*/

/*.article-switcher .processing-article {*/
/*  font-size: 13px;*/
/*  font-weight: 700;*/
/*  line-height: 16px;*/
/*  letter-spacing: -0.01em;*/
/*  color: var(--default-symbols-secondary);*/
/*  display: flex;*/
/*  align-items: center;*/
/*  gap: 4px;*/
/*  cursor: pointer;*/
/*}*/

/*.processing-article.loading {*/
/*  cursor: initial*/
/*}*/

/*.article-switcher .processing-article svg {*/
/*  width: 12px;*/
/*  height: auto;*/
/*}*/

/*.article-switcher .processing-article path {*/
/*  fill: var(--default-symbols-secondary)*/
/*}*/

.editor-content-info .toggle-styling {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: var(--default-symbols-secondary);
  text-transform: lowercase;
  cursor: pointer;
}

.editor-content-info .toggle-styling:first-letter {
  text-transform: uppercase;
}

.toggle-styling svg {
  width: 12px;
  margin-left: 6px;
}

.toggle-styling path {
  fill: var(--default-symbols-secondary);
}

.editor-content-info input {
  background: var(--default-surfaces-light);
  border: 2px solid transparent;
}

.editor-content-info input:focus {
  border-color: var(--hero-borders-anchor);
  background: var(--default-surfaces-base)
}
/* .article-actions {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-grow: 1;
} */
.article-action {
  background: var(--default-surfaces-light);
  color: var(--default-symbols-primary);
  padding: 6px 8px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
}
.article-action.brand {
  background-color: var(--night-surfaces-anchor);
  color: var(--inverted-symbols-primary)
}
.article-action.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.article-action svg {
  width: 14px;
  height: 14px;
  fill: currentColor;
}


.current-article-info {
  color: var(--gray-symbols-primary);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  max-width: 50%;
  white-space: nowrap;
}
.current-article-info strong {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.current-article-info svg {
  width: 16px;
  min-width: 16px;
  height: 16px;
}