.image-input-url{
  color: var(--color-light-text);
  border-radius: var(--m, 12px);
  background: var(--default-surfaces-light, rgba(23, 23, 23, 0.05));
  padding: 12px 16px;
  width: 404px;
  display: flex;
  justify-content: space-between;
}

.image-input-container{
  width: 100%
}
.custom-file-uploader {
  margin-top: 16px;
  display: flex;
}
.custom-file-uploader__content {
  position: relative;
}
.custom-file-uploader__content .hidden-input {
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  clip: unset;
  clip-path: none;
  opacity: 0;
}
/*.input-label {*/
/*  display: flex;*/
/*}*/
.editor-upload{
  color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.50));
  /* Info/Primary/Normal */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.13px;
  display: flex;
  align-items: center
}

.editor-upload svg{
  margin-right: 4px
}
.image-input-url  input {
  /* border: 1px solid var(--color-inactive-input); */
  /* border-radius: 7px; */
  /* background: white; */
  /* padding: 8px 10px; */
  /* color: var(--color-light-text); */
  margin-right: 15px;
  border: none;
  background: none;
  outline: none;
  flex-grow: 1;
  padding: 0 ;
  width: 100%
  
}
.upload-spinner-image{
    color: var(--default-symbols-primary, #171717);
  /* Info/Primary/Normal */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.13px;
  display: flex;
  align-items: center;
  margin-top: 16px
}
.upload-spinner-image svg{
  margin-right: 4px;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.image-input-url  input::placeholder{
  color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.50));
  color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.50));
  text-overflow: ellipsis;
  /* Info/Identity/Bold */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
  letter-spacing: -0.15px;
}

.upload-image-error-text{
  color: var(--danger-symbols-primary, #EB4F4F);
  /* Info/Primary/Normal */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.13px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.image-input-url label{
  color: var(--default-symbols-primary, #171717);
/* Info/Secondary/Extrabold */
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 800;
line-height: 14px; /* 127.273% */
display: block
}

.insert-image-btn {
  /* padding: 8px 20px;
  background: #6870f6;
  border: solid 2px #6870f6;
  border-radius: 8px;
  color: white;
  cursor: pointer; */
  padding: var(--M, 8px) var(--L, 16px);
  border-radius: 20px;
  background: var(--default-surfaces-anchor, #171717);
  outline: none;
  color: var(--inverted-symbols-primary, #FFF);
  border: none;
  cursor: pointer
}

.insert-image-btn.disabled-image{
  color: var(--inverted-symbols-secondary, rgba(255, 255, 255, 0.50));
  background: var(--default-surfaces-dark, rgba(23, 23, 23, 0.30));
  pointer-events:none
}

.image-opener {
  margin-top: 60px;

}

.image-opener .MuiPaper-root{
  padding: 16px;
  border-radius: var(--m, 12px) !important;
  background: var(--default-surfaces-base, #FFF) !important;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.10) !important;
}
.image-uploader-container{
  position: relative
}

.image-upload-popover{
  position: absolute;
  top: 10px;
  border-radius: var(--m, 12px);
  background: var(--default-surfaces-base, #FFF);

/* Block/Tooltip */
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.10);
}

.editor-upload {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  font-weight: 500;
}