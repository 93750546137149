/* Basic editor styles */
.ProseMirror:not(.view-only) > * {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: -4px;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: transparent;
    }
    &:hover:after {
        background-color: var(--default-surfaces-dark);
    }
}

.ProseMirror > * + *,
.ProseMirror section > * + * {
  margin-top: 0.75em;
}
.ProseMirror h1 {
  position: relative;
}

/* Give a remote user a caret */
.ProseMirror .collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.ProseMirror .collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}
.ProseMirror h1.is-editor-empty {
  height: 36px;
}
.ProseMirror h1.is-empty:first-child::before,
.heading-is-empty .ProseMirror h1:first-child::before {
  content: attr(data-placeholder);
  line-height: 1.1;
  /* font-family: "Inter UI"; */
  font-weight: 900;
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2 ,
.ProseMirror h3,
.ProseMirror h4 ,
.ProseMirror h5 ,
.ProseMirror h6 {
  line-height: 1.1;
  /* font-family: "Inter UI"; */
  /* font-weight: 900; */
  color: #171717;
}

.ProseMirror h1 > *,
.ProseMirror h2 > *,
.ProseMirror h3 > *,
.ProseMirror h4 > *,
.ProseMirror h5 > *,
.ProseMirror h6 > * {
  line-height: 1.1;
  font-family: "Inter";
  /* font-weight: 900; */
  color: #171717;
}

.ProseMirror h1{

/* Display/L/Extrabold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.64px;
}

.ProseMirror h2{
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: -0.48px;
}

.ProseMirror h3{
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.ProseMirror h4{
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.15px;
}

.ProseMirror li,
.ProseMirror p{
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.13px;

}

.ProseMirror p a {
  color: var(--night-symbols-primary);
  padding-bottom: 2px;
  border-bottom: 1px solid;
  cursor: pointer;
}

.ProseMirror p a:hover {
  color: var(--night-symbols-primary-hv);
}

/* .ProseMirror p {
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  color: var(--color-light-text);
} */

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  /* font-family: "JetBrainsMono", monospace; */
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.editor-wrapper {
  height: calc(100vh - 40px);
  background: #eaeaea
}

.editor-wrapper:not(.outline-mode) .editor-content .refresh-title {
  display: none !important;
}

.editor-wrapper .editor-content .refresh-title:not(:last-child) {
  display: none !important;
}

.editor-wrapper .editor-content .refresh-title path {
  fill: var(--night-symbols-primary);
}

@media (max-width: 1285px) {
  .editor-wrapper .editor-content .refresh-title {
    left: 70px;
  }
}

.editor-content__inner-wrapper {
  border-radius: 8px;
  background: #fff;
  max-width: 800px;
  margin: 0 auto 16px;
  width: 100%;
  height: auto;
  padding: 24px 36px 60px;
}
.editor-content__inner-wrapper .title-label {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
}

.title-label__wrapper {
  display: flex;
  align-items: center;
}


.title-label__wrapper .refresh-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: var(--night-symbols-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  z-index: 1;
  margin-left: 6px;
}
.title-label__wrapper .refresh-title svg {
  width: 16px;
  height: auto;
}
.title-label__wrapper .refresh-title path {
  fill: var(--night-symbols-primary);
}
.title-label__wrapper .refresh-title.regenerate-disabled{
  color: var(--default-symbols-secondary);
  cursor: not-allowed
}
.title-label__wrapper .refresh-title.regenerate-disabled path{
  fill: var(--default-symbols-secondary);
}

.editor-wrapper .editor-content {
  /*height: 100%;*/
  /*overflow: auto;*/
  position: relative;
  display: flex;
  flex-direction: column;
}

.editor-wrapper .ProseMirror.view-only ol ol,
.editor-wrapper .ProseMirror.view-only ol ul,
.editor-wrapper .ProseMirror.view-only ul ol,
.editor-wrapper .ProseMirror.view-only ul ul{
  margin-left: -16px;
}

.editor-wrapper .ProseMirror.view-only section,
.editor-wrapper .ProseMirror.view-only ol,
.editor-wrapper .ProseMirror.view-only ul {
  display: grid;
}

.editor-wrapper .ProseMirror {
  padding: 0 36px;
  margin: 0 -36px;
  /*display: grid;*/
}

.ProseMirror:focus {
  outline: none;
}

.editor-content-container{
  padding: 0 16px 120px;
}

.ProseMirror.view-only .ProseMirror-trailingBreak {
  display: none;
}

.ProseMirror.loading {
  color: var(--default-symbols-secondary)
}



.connection-error {
  background-color: #fff3cd;
  color: #856404;
  padding: 12px;
  margin: 8px 0;
  border-radius: 4px;
  text-align: center;
}

.ProseMirror {
    padding-inline: 4rem;

    > * + * {
        margin-top: 0.75em;
    }
}

.drag-handle {
    align-items: center;
    cursor: grab;
    height: 16px;
    width: 16px;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
        fill: var(--default-surfaces-dark);
    }

    &:hover {
        svg {
            fill: var(--default-surfaces-dark-hv);
        }
    }

    &:active {
        svg {
            fill: var(--default-symbols-secondary)
        }
    }
}

.drag-handle-button {
    cursor: grab;
    color: #888;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drag-handle-button:hover {
    color: #333;
}

/*!* Text Menu Styles *!*/
/*.text-menu {*/
/*    display: flex;*/
/*    background-color: #fff;*/
/*    padding: 0.5rem;*/
/*    border-radius: 0.5rem;*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
/*    border: 1px solid #e2e8f0;*/
/*}*/

/*.text-menu button {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    width: 2rem;*/
/*    height: 2rem;*/
/*    margin: 0 0.25rem;*/
/*    padding: 0.25rem;*/
/*    border: none;*/
/*    background: none;*/
/*    border-radius: 0.25rem;*/
/*    color: #4a5568;*/
/*    cursor: pointer;*/
/*    transition: all 0.2s;*/
/*}*/

/*.text-menu button:hover {*/
/*    background-color: #f7fafc;*/
/*    color: #2d3748;*/
/*}*/

/*.text-menu button.is-active {*/
/*    background-color: #ebf4ff;*/
/*    color: #4299e1;*/
/*}*/

/*.text-menu button svg {*/
/*    width: 1.25rem;*/
/*    height: 1.25rem;*/
/*}*/

/*.text-menu .separator {*/
/*    width: 1px;*/
/*    height: 1.5rem;*/
/*    margin: 0 0.5rem;*/
/*    background-color: #e2e8f0;*/
/*}*/

.hidden {
    display: none !important;
}