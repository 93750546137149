@keyframes spinAnimation {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

.article-progress-box {
    display: flex;
    padding: 16px 24px;
    gap: 24px;
    color: var(--default-symbols-primary);
    width: 100%;
    max-width: 800px;
    margin-inline: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    background: #fff;
    border-radius: 8px;
    position: relative;
}

.article-progress-box-pie {
     /*flex-grow: 1;*/
}

.progress-pie__wrapper {
    padding: 8px;
}

.progress-pie {
    height: 103px;
    width: 103px;
}

.article-progress-box-steps{
    flex-grow: 1
}

.progress-time-remaining {
    padding: 2px 8px 16px;
}

.progress-time-remaining .counter {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.02em;
}

.progress-time-remaining .counter-label {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
}
.progress-step {
    display: flex;
    align-items: center;
    margin: 8px 0;
    gap: 4px;
}
.progress-step-icon {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-step--active .progress-step-icon {
    animation: spinAnimation infinite 2s linear;
}

.progress-step--loaded.outline-check path {
    fill: var(--night-symbols-primary)
}
.progress-step--loaded.article-check path {
    fill: var(--hero-symbols-primary)
}
.progress-step--loading, .progress-step--loading path {
    color: var(--default-symbols-secondary);
    fill: var(--default-symbols-secondary)
}
.progress-step-description {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
.article-progress-box-icon {
    position: absolute;
    right: 24px;
    top: 16px;
}

.progress-question {
    margin-bottom: 4px;
    font-weight: 700;
}