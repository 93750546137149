.homepage {
  display: flex;
  width: 100%;
  height: 100%;
}

.homepage-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*padding-top: 30px*/
}

.homepage-content {
  display: flex;
  /*width: 95%;*/
  justify-content: space-between;
  position: fixed;
  /*top: 100px;*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.homepage-editor {
  flex: 1;
  background: #eaeaea;
}

.search-topic__modal-content {
  padding: 0;
}
