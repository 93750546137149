@keyframes blink {
    0%, 50%, 100% {
        opacity: 1; /* Fully visible */
    }
    25%, 75% {
        opacity: 0; /* Fully transparent */
    }
}
.ai-card__wrapper {
    width: 100%;
    max-width: 800px;
    margin-inline: auto;
    position: relative;
    z-index: 2;
}
.ai-card {
    width: 100%;
    padding: 16px 36px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.01em;
    margin-bottom: 24px;
}

.ai-card--outline {
    background: var(--night-symbols-alternative);
    color: var(--inverted-symbols-primary);
}

.ai-card--article {
    background: var(--night-surfaces-medium);
    color: var(--night-symbols-primary);
}

.ai-card__content {
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.ai-card-text {
    max-width: 400px;
    flex-grow: 1;
}

.ai-card-text:not(.ai-card-text--big) {
    padding-top: 7px;
}

.ai-card-text.ai-card-text--big {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    max-width: none;
}

.ai-card--full-text .ai-card-text {
    max-width: 100%;
}

.ai-card--outline:not(.ai-card--generating-outline) .ai-card-text {
    /*max-width: 340px;*/
}
.ai-card--article .ai-card-text,
.ai-card--article.ai-card--generating-article .ai-card-text {
    max-width: 100%;
    padding-top: 0;
}

.ai-card-options {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.ai-card-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    padding-top: 2px;
}

.no-articles-left .ai-card-button,
.ai-card-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.ai-card-button {
    padding: 8px 16px;
    border-radius: 50px;
    background: var(--night-symbols-primary);
    color: var(--inverted-symbols-primary);
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    white-space: nowrap;
}

.ai-card-button--reset {
    background: var(--night-symbols-primary);
    color: var(--inverted-symbols-primary);
}

.ai-card-button--show-prompt {
    background: var(--inverted-surfaces-anchor);
    color: var(--default-symbols-primary);
}

.ai-card--generating-outline .ai-card-button {
    cursor: not-allowed;
}
.ai-card--generating-outline .ai-card-button--reset {
    background: var(--inverted-surfaces-light);
    color: var(--inverted-symbols-secondary);
}

.ai-card--generating-outline .ai-card-button--show-prompt {
    background: var(--inverted-surfaces-dark);
    color: var(--default-symbols-secondary);
}

.ai-card-button--generate {
    font-size: 15px;
    line-height: 18px;
    padding-inline: 12px;
}

.ai-card-prompt__wrapper {
    display: flex;
    gap: 16px;
    margin-top: 16px;
}
.ai-card--article .default-prompt-info {
    color: var(--night-symbols-primary)
}
.default-prompt-info {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    color: var(--inverted-symbols-primary);
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 4px;
}
.default-prompt-info b {
    cursor: pointer;
}
.default-prompt-info svg {
    height: 14px;
    width: 14px;
}
.default-prompt-info path {
    fill: var(--inverted-symbols-primary)
}
.ai-card--article .default-prompt-info path {
    fill: var(--night-symbols-primary)
}
.ai-card-prompt {
    /*width: 440px;*/
    /*margin-left: 32px;*/
    flex-grow: 1;
}
.ai-card-prompt__textarea-wrapper {
    position: relative;
    border-radius: 12px;
    line-height: 0;
    overflow: hidden;
}
.ai-card-prompt .ai-card-button {
    position: absolute;
    top: 12px;
    right: 16px;
    z-index: 2;
}

.ai-card-prompt .prompt-label {
    position: absolute;
    color: var(--default-symbols-primary);
    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: 0em;
    padding-top: 12px;
    padding-left: 16px;
    right: 115px;
    left: 0;
    top: 0;
    border-radius: 12px 0 0 0;
    background: #fff;
}

.ai-card-prompt .prompt__counter {
    position: absolute;
    bottom: 12px;
    right: 16px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
}

.ai-card-prompt .prompt__counter.exceed {
    color: var(--danger-symbols-primary)
}

.ai-card-prompt textarea {
    padding: 26px 16px 36px 16px;
    border-radius: 12px;
    width: 100%;
    height: 58px;
    outline: none;
    resize: vertical;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
    border: none;
    min-height: 180px;
}

.ai-card-prompt textarea::placeholder {
    color: var(--default-symbols-secondary);
}

.ai-card-prompt textarea:active::placeholder,
.ai-card-prompt textarea:focus::placeholder {
    color: transparent !important
}

.blinking-dot {
    height: 14px;
    width: 14px;
    border-radius: 50px;
    display: inline-block;
    margin-left: 4px;
    margin-bottom: -2px;
    margin-right: -18px;
    background: var(--inverted-symbols-primary);
    animation: blink 1.5s infinite;
}

.ai-card--article .blinking-dot {
    background: var(--night-symbols-primary);
}
.writer-source{
    /* margin-top: 8px; */
    font-size: 11px;
    cursor: pointer;
    width: 282px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.writer-source-value{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
     padding: 4px 8px;
    border: 1px solid white;
    border-radius: 16px;

}

.writer-source .writer-source-value{
    font-size: 11px
}

.configure-writer-source-button{
    margin-top: 10px;
    width: 100%;
    background: black;
    color: white;
    outline: none;
    border: 1px solid white;
    padding: 8px
}

.writer-options{
    /* border-radius: 6px;
    padding: 4px 10px;
    font-size: 12px;
    margin: 4px 0;
    color: #64656f;
    cursor: pointer */
    margin-top: 10px
}

.writer-options:hover{
    background: #eaeaea;

}

.writer-options.active{
     color: #ffffff;
    background-color: #64656f;
    border-radius: 6px;
}

.active p{
    color: #ffffff;
}
.arrow-icon-ai{
    margin-left: 8px;
    color: #ffffff
}

.switcher-wrapper {
    /*min-width: 340px;*/
    min-width: 260px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.switcher-wrapper .app-switcher {
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 44px;
    max-height: 44px;
}

.ai-card__wrapper--show-links .ai-card__content,
.ai-card__wrapper--show-links .ai-card-buttons,
.ai-card-buttons.ai-card-buttons--subscribe {
    align-items: center;
}
.ai-card-buttons.ai-card-buttons--subscribe {
    align-self: center;
    padding: 0;
    gap: 16px;
    margin-left: 16px;
}
.article-configuration__steps {
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    white-space: nowrap;
}

.ai-card--outline .switcher-label {
    color: var(--inverted-symbols-primary);
}

.ai-card--outline .checkmark,
.ai-card--outline .switcher:hover input ~ .checkmark {
    background-color: var(--inverted-surfaces-medium);
}
.ai-card--outline .switcher input:checked ~ .checkmark {
    background-color: var(--inverted-surfaces-anchor-hv);
}

.ai-card--outline .switcher .checkmark:after {
    background: var(--night-symbols-primary);
}

.generate-images {
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding: 2px 0;
    gap: 4px;
}
.generate-images svg {
    fill: var(--inverted-symbols-primary);
}

.generate-images .beta-label {
    margin-left: 4px;
    background: var(--inverted-surfaces-light);
    padding: 4px 6px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
}
