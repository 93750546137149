.editor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #171717;
    padding: 16px 36px 0;
    z-index: 3;
    position: relative;
}

.edit-header__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}
.edit-header__right  .right-wrapper{
    position: relative
}
.edit-header__right .right-header__search_volume{
    display: flex;
    align-items: center;
    color: var(--default-symbols-secondary);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.15px;
    padding: 4px 12px;
    cursor: pointer;
    position: relative;
    background: var(--default-surfaces-light);
    border-radius: 18px;
}

.edit-header__right .right-header__search_volume .header-search_volume{
    color: var(--default-symbols-primary, #171717);
/* Display/Identity/Extrabold */
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: -0.15px;
    margin-left: 4px
}
.right-header__search_volume:hover + .traffic-dropdown-container{
    /*display: block*/
}
.action-section{
    position: relative
}
.header-download_action {
    color: var(--default-symbols-secondary);
    padding: 8px 16px;
    cursor: pointer;
    background: var(--default-surfaces-light);
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor : pointer
}
.header-download_action svg {
    width: 16px;
    height: 16px;
}
.header-download_action path {
    fill: var(--default-symbols-primary)
}
.all-keywords-button {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
    letter-spacing: -0.48px;
    color:var(--default-symbols-primary)
}

.market-icon {
    background: var(--default-surfaces-light);
    border-radius: 50px;
    padding: 4px 6px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--default-symbols-secondary);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    margin-bottom: -3px;
}

.market-icon svg {
    width: 12px;
    height: 12px;
}
.market-icon path {
    fill: var(--default-symbols-secondary);
}

.all-keywords-button__label{
    color: var(--default-symbols-secondary);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
}

.topic-info {
    text-align: center;
}

.topic-info__name {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.topic-info__volume {
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: rgba(23, 23, 23, 0.5);
}

.score-pill {
    padding: 8px;
    background: #f1f7ef;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    gap: 4px;
}

.score-pill__label {
    font-weight: 700;
}

.score-pill__value {
    font-weight: 600;
    color: rgba(65, 150, 44, 1);
}

.editor-header > * {
    /* flex-basis: calc(100% / 3); */
}
.publish-button {
    border-radius: 70px;
    background: var(--default-symbols-primary);
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor : pointer;
    color: var(--inverted-symbols-primary);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    gap: 4px;
}
.publish-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.publish-button svg {
    width: 16px;
    height: 16px;
    fill: var(--inverted-symbols-primary)
}

.publish-button.small-btn {
    padding: 6px 8px;
    gap: 2px;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
}
.publish-button.brand {
    background-color: var(--night-surfaces-anchor);
    color: var(--inverted-symbols-primary);
}

.publish-button.brand svg {
    fill: var(--inverted-symbols-primary);
    width: 14px;
    height: 14px;
}
.publish-button.is-published {
    cursor: initial;
}