.article-keywords-table {
    margin-top: 24px;
    padding-bottom: 80px;
}
.article-keywords-table.track-mode {
    padding-bottom: 100px;
}
.article-keywords-table.loading * {
    pointer-events: none !important;
}
.article-keywords-table.loading > * {
    opacity: 0.4;
}
.article-keywords-table__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--default-borders-light);
}
.total-tracked-keywords {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    cursor: pointer;
    background: var(--default-surfaces-ghost-hv);
    color: var(--default-surfaces-anchor);
    padding: 4px 8px;
    border-radius: 50px;
}
.total-tracked-keywords.all-tracked {
    cursor: default;
}

.track-mode .total-tracked-keywords {
    background: var(--default-surfaces-anchor);
    color: var(--inverted-symbols-primary);
}

.article-keywords-table-column {
    padding: 8px 16px 8px 8px;
}

.article-keywords-table,
.article-keywords-table input::placeholder {
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
}

.article-keywords-table-row .column--keyword {
    font-size: 15px;
    line-height: 22px;
    display: block;
    position: relative;
}

.column--keyword,
.column--volume,
.column--presence {
    box-sizing: content-box;
}

.column--keyword {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.column--volume {
    flex-basis: 48px;
    min-width: 48px;
    text-align: right;
    justify-content: end
}
.column--presence {
    /*flex-basis: 59px;*/
    /*min-width: 59px;*/
    flex-basis: 77px;
    min-width: 77px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}
.progress-percentage {
    width: 35px;
    text-align: right;
}


.article-keywords-table-row .column--keyword:hover .delete-keyword {
    display: flex;
}
.article-keywords-table-row .delete-keyword {
    display: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.article-keywords-table-row .loading-delete-keyword{
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
}

.article-keywords-table-row .loading-delete-keyword path{
    animation: rotate 2s linear infinite;
    transform-origin: center center;
}
.article-keywords-table-row .delete-keyword path {
    fill: var(--default-symbols-secondary);
}
.article-keywords-table-row .delete-keyword:hover path {
    fill: var(--default-symbols-primary)
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.track-checkbox {
    cursor: pointer;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50px;
    background: rgba(23, 23, 23, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.track-checkbox svg {
    height: 16px;
    width: 16px;
}
.track-checkbox.track-checkbox--checked:after {
    content: "";
    height: 9px;
    width: 9px;
    position: absolute;
    display: block;
    background: #171717;
    border-radius: 50px;
}
.track-checkbox.track-checkbox--tracked {
    background: rgba(23, 23, 23, 0.5);
}
.track-checkbox.track-checkbox--tracked svg path {
    fill: white;
}
