.traffic-dropdown-container{
    background: var(--default-surfaces-base);
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.10);
    border-radius: 12px;
    padding: 16px;
    position: absolute;
    z-index: 1;
    /*min-width: 413px;*/
    min-width: 354px;
    right: 0px;
    top: 35px;
    /*display: none*/
}
.traffic-dropdown-container .statistics-description{
    color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.50));
    /* Display/Secondary/Normal */
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
}
.traffic-dropdown-container .statistics-row{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px
}
.traffic-dropdown-container .statistics-item{
    min-width: 72px
}

.statistics-item .statistics-title{
    color: var(--default-symbols-primary, #171717);
    /* Info/Secondary/Bold */
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 127.273% */
    margin-bottom: 4px
}

.statistics-row .statistics-item .main-score{
    color: var(--default-symbols-primary, #171717);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 100% */
    letter-spacing: -0.72px;
}

.statistics-row .statistics-item sup {
    color: var(--default-symbols-primary, #171717);
    vertical-align: super;
    font-size: 10px;
    margin-left: 4px;
    line-height: 9px;
}

.statistics-item sup.positive{
  color: var(--positive-symbols-primary, #41962C);
}

.statistics-item sup.negative{
color: var(--negative-symbols-primary, #DC4321);
}