.app-tab-selector{
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 70px;
    padding: 2px;
    background: var(--default-surfaces-light, rgba(23, 23, 23, 0.05))
}

.app-tab-selector.single-option {
    padding: 0;
}
.app-tab-selector.single-option .switch-item {
    padding: 8px 14px;
}
.app-tab-selector__icon {
    font-size: 0;
    line-height: 0;
}
.app-tab-selector .switch-item{
    color: var(--default-symbols-primary, #171717);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.13px;
    padding: 6px 12px;
    text-align: center;
    cursor: pointer;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.app-tab-selector .switch-item--auto {
    width: auto;
}

.app-tab-selector .switch-item .app-tab-selector__label  {
    display: inline-block;
    vertical-align: middle;
}

.app-tab-selector__info {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: var(--default-symbols-secondary)
}

.app-tab-selector .switch-item svg {
    width: 16px;
    height: 16px;
}

.app-tab-selector .switch-item path {
    fill: var(--default-symbols-primary);
}

.switch-item.disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.app-tab-selector .switch-item.active{
    border-radius: 70px;
    background: var(--default-surfaces-base, #FFF);
}

.app-tab-selector.brand {
    background: var(--night-symbols-primary)
}

.app-tab-selector.brand .switch-item {
    color: var(--inverted-symbols-primary)
}

.app-tab-selector.brand .switch-item path {
    fill: var(--inverted-symbols-primary)
}

.app-tab-selector.brand .switch-item.active{
    background: var(--default-surfaces-base, #FFF);
    color: var(--default-symbols-primary)
}

.app-tab-selector.brand .switch-item.active path {
    fill: var(--default-symbols-primary)
}