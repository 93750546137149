.dashboard-page {
  height: 100vh;
  max-height: 100vh;
  background: rgba(23, 23, 23, 0.05);
  overflow: hidden;
}
.dashboard-page--sidebar {
  display: flex;
}
.dashboard-page--sidebar .articles-sidebar__wrapper {
  height: 100%;
}
.dashboard-page--sidebar .new-article-modal {
  max-width: 800px;
  margin: 0 auto;
}
.dashboard-page--published .dashboard-page__content {
  padding-top: 16px;
}

.dashboard-page__content {
  padding: 48px 24px;
  flex-grow: 1;
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
